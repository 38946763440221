import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const Team = () => {
	const data = useStaticQuery(graphql`
		query {
			placeholderImage: file(relativePath: { eq: "team2.jpg" }) {
				childImageSharp {
					fixed {
						...GatsbyImageSharpFixed
					}
				}
			}
		}
	`);

	return (
		<Img
			alt="A group of people putting their hands together."
			fixed={data.placeholderImage.childImageSharp.fixed}
		/>
	);
};

export default Team;
