import React from 'react';
import { Link } from 'gatsby';

import HomeLayout from '../layout/homepage';

import Summary from '../components/summary';
import Products from '../components/products';

// SEO imports
import SEO from '../components/seo';
import ogImage from '../assets/images/og-images/adaptix.jpg';

const ServicesPage = () => (
	<HomeLayout>
		<SEO
			title="Services | Strong Mind Performance Consulting"
			description="Strong Mind Performance Consulting focuses in performing under pressure, buliding confidence, creating mental routines, enhancing focus, managing emotions, and controlling anxiety."
			image={ogImage}
		/>
		<Summary />
		<Products />
	</HomeLayout>
);

export default ServicesPage;
