import React from 'react';
import { Link } from 'gatsby';

import Fade from 'react-reveal/Fade';

function Summary() {
	return (
		<section id="summary">
			<Fade>
				<div className="container">
					<div className="summary__intro">
						<h1 className="shout">
							Strong Mind's<br /> Area's of Interest:
						</h1>
						<p className="talk">These are the areas Strong Mind is most experienced in.</p>
					</div>
					<ul>
						<li>
							<i className="fa fa-trophy" />
							<span className="whisper">Performing Under Pressure</span>
						</li>
						<li>
							<i className="fa fa-award" />
							<span className="whisper">Building Confidence</span>
						</li>
						<li>
							<i className="fa fa-list" />
							<span className="whisper">Creating Mental Routines</span>
						</li>
						<li>
							<i className="fa fa-eye" />
							<span className="whisper">Enhancing Focus</span>
						</li>
						<li>
							<i className="fa fa-smile" />
							<span className="whisper">Managing Emotions</span>
						</li>
						<li>
							<i className="fa fa-cogs" />
							<span className="whisper">Controlling Anxiety</span>
						</li>
					</ul>
				</div>
			</Fade>
		</section>
	);
}

export default Summary;
