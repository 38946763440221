import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const Individual = () => {
	const data = useStaticQuery(graphql`
		query {
			placeholderImage: file(relativePath: { eq: "individual1.jpg" }) {
				childImageSharp {
					fixed {
						...GatsbyImageSharpFixed
					}
				}
			}
		}
	`);

	return <Img alt="An athelte exercising." fixed={data.placeholderImage.childImageSharp.fixed} />;
};

export default Individual;
