import React from 'react';
import { Link } from 'gatsby';

import Individual from './images/individual';
import Team from './images/team';

import Fade from 'react-reveal/Fade';

function Products() {
	return (
		<section id="products">
			<div className="products__content">
				<div className="container">
					<Fade>
						<h2 className="shout">Individual Performance</h2>
					</Fade>
					<Fade bottom>
						<div className="products__content-product">
							<Individual />
							<div className="products__content-product-summary">
								<h3 className="yell">Up your game</h3>
								<p className="talk">
									Every performer is unique and different in their own way. During individual
									sessions, we will work together to analyze your performances and create a Mental
									Performance Toolkit. The Toolkit will consist of tailored mental skills that suit
									your needs to help enhance your performances.
								</p>
								<Link className="btn btn__accent" to="/contact/">
									Schedule a consultation
								</Link>
							</div>
						</div>
					</Fade>
					<Fade>
						<h2 className="shout">Team Performance</h2>
					</Fade>
					<div>
						<Fade>
							<div className="products__content-product">
								<Team />
								<div className="products__content-product-summary">
									<h3 className="yell">There's no &ldquo;I&rdquo; in team</h3>
									<p className="talk">
										When working with teams, we take pride in tailoring specific resources for the
										team’s needs while building a unique culture that enables growth, encouragement,
										and accountability.
									</p>
								</div>
								<div className="products__content-product-phases">
									<h4 className="declare">
										Our process is broken down<br /> into three phases:
									</h4>
									<ul>
										<li>
											<i className="fa fa-user" />
											<h5 className="whisper">
												Phase I<br />
												<span>(Coaches Development)</span>
											</h5>
											<p className="talk">
												During phase I we help coaches create a coaching philosophy and culture
												to match their specific style.
											</p>
										</li>
										<li>
											<i className="fa fa-users" />
											<h5 className="whisper">
												Phase II<br />
												<span>(Team Development)</span>
											</h5>
											<p className="talk">
												During phase II we work with the team to develop season goals, mission
												statements, and overall team values.
											</p>
										</li>
										<li>
											<i className="fa fa-hand-paper" />
											<h5 className="whisper">
												Phase III<br />
												<span>(Applied Skills)</span>
											</h5>
											<p className="talk">
												During phase III we work with the team to help enhance their ability to
												perform by applying mental skills.
											</p>
										</li>
									</ul>
									<Link className="btn btn__accent" to="/contact/">
										Schedule a consultation
									</Link>
								</div>
							</div>
						</Fade>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Products;
